<template>
  <div class="py-5">
    <div class="pb-2 my-4">
      <b-card-title class="pb-0 mb-0 text-bold-24 text-font-main">
        {{ $t('common.sign_in') }}
      </b-card-title>
      <b-card-text class="mt-0 text-bold-16 text-font-secondary">
        {{ $t('common.please_enter_password_email') }}
      </b-card-text>
    </div>
    <wameed-form
      refs=""
      @submitAction="login"
    >
      <!-- form -->
      <template
        slot="form-content"
        slot-scope="{ invalid }"
      >
        <!-- email -->
        <div class="pt-1">
          <text-input
            id="login-email"
            v-model="email"
            :label="$t('common.email')"
            input-classes="text-bold-14 text-font-main"
            field-classes="pb-1 login-inputs"
            name="email"
            rules="required|email"
            placeholder="Info@example.com"
          />

          <!-- forgot password -->
          <text-input
            id="login-password"
            v-model="password"
            :label="$t('common.password')"
            field-classes=" login-inputs mb-2"
            input-classes="text-bold-14 text-font-main"
            name="password"
            rules="required"
            :icon="passwordToggleIcon"
            :type="passwordType"
            is-append
            :placeholder="$t('common.placeholder_password')"
            @iconClick="
              passwordType == 'password'
                ? (passwordType = 'text')
                : (passwordType = 'password')
            "
          />
          <div class="d-flex justify-content-end">
            <wameed-btn
              :title="$t('common.forget_password')"
              variant="link"
              :classes="'px-0 text-bold-14 text-font-sub my-0 pt-0'"
              @submitAction="goTo('forgot-password')"
            />
          </div>

          <!-- submit buttons -->
          <wameed-btn
            :disabled="invalid"
            :title="$t('common.sign_in')"
            block
            type="submit"
            variant="main"
            :classes="' text-reg-14 text-white d-flex align-items-center rounded-10  justify-content-center my-2'"
          />
        </div>
      </template>
    </wameed-form>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { mapActions } from 'vuex';
import store from '@/store/index';

import TextInput from '@/components/TextInput.vue';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import WameedSwitch from '@/components/WameedSwitch.vue';
// import TextInput from '@/components/TextInput.vue'

export default {
  // directives: {
  //   'b-tooltip': VBTooltip,
  // },
  components: {
    TextInput,
    WameedForm,
    WameedBtn,
    WameedSwitch,
  },
  data() {
    return {
      status: '',
      password: '',
      email: '',
      sideImg: 'login-bg',
      rememberMe: false,
      passwordType: 'password',
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordType === 'password' ? 'eyeon-icon' : 'eyeoff-icon';
    },
  },
  methods: {
    ...mapActions({ submitLogin: 'submitLogin' }),
    login() {
      // this.$refs.loginForm.validate().then((success) => {
      //   if (success) {
      this.submitLogin({
        email: this.email,
        password: this.password,
        remember_me: this.rememberMe,
        type: 'super',
      });
    },
    async goTo(value) {
      await this.$router.push({ name: value, force: true });
    },
  },
};
</script>
